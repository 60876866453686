moya.search = window.moya.search || {};

moya.search = {
	initAutocomplete: function(selector) {
		var $element = $(selector);
		if ($element.length) {
			$element.autocomplete({
				source: function( request, response ) {
					$.ajax({
						url: "/_/moya/search/ajax/autocomplete",
						dataType: "json",
						data: {
							q: encodeURIComponent(request.term)
						},
						success: function( data ) {
							response( data );
						}
					});
				},
				minLength: 3,
				select: moya.search.autocompleteSelect,
				focus: moya.search.autocompleteFocus,
				close: moya.search.autocompleteClose,
			}).autocomplete( "instance" )._renderItem = moya.search.autocompleteRender;
		}
	},
	autocompleteSelect: function(event, ui) {
		if (ui.item && ui.item.value.url) {
			window.location = ui.item.value.url;
		}

		return false;
	},
	autocompleteFocus: function(event, ui) {
		// Do nothing
		return false;
	},
	autocompleteClose: function(event, ui) {
		// Do nothing
		return false;
	},
	autocompleteRender: function( ul, item ) {
		return $( "<li>" )
			.append('<span class="result__type">' + item.value.type + '</span>')
			.append('<span class="result__label">' + item.label + '</span>')
			.appendTo( ul );
	}
};
