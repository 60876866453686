if (document.layers) document.captureEvents(Event.CLICK);
if (document.layers || document.all) document.onmouseclick = closeJip;
if (document.addEventListener) document.addEventListener('click', closeJip, true);

function closeJip(e) {
	$('.jip ul').addClass('hidden');
}

$(function(){
	$('.jip > a').bind('click', function(e){
		var ul = $(this).next('ul');
		if (ul.length > 0) {
			if (ul.hasClass('hidden')) {
				ul.removeClass('hidden');
			}
			else {
				ul.addClass('hidden');
			}
			if (ul.offset().left > $(window).width() / 2) {
				ul.css('margin-left', '-176px');
			}
			if (e && e.preventDefault) {
				e.preventDefault();
			}
		}
	});
});
