moya = window.moya || {};

moya.settings = {
	diffData: {},
	urls: {},
	lang: {},
	init: function(diffData, urls, lang) {
		moya.settings.diffData = diffData;
		moya.settings.urls = urls;
		moya.settings.lang = lang;

		$('#settings .ui-accordion-header').click(function(){
			var $this = $(this)
			var $dl = $this.next('div.ui-accordion-content');
			if (!$dl.hasClass('hidden')) {
				$dl.addClass('hidden');
				$this.removeClass('ui-state-focus open').addClass('ui-state-default');
				$this.find('span').addClass('ui-icon-triangle-1-e').removeClass('ui-icon-triangle-1-s');
			}
			else {
				$dl.removeClass('hidden');
				$this.addClass('ui-state-focus open').removeClass('ui-state-default');
				$this.find('span').removeClass('ui-icon-triangle-1-e').addClass('ui-icon-triangle-1-s');
			}
		});
		$('#settings .ui-state-default').hover(function() {
			if ($(this).hasClass('open')) return;
			$(this).addClass('ui-state-focus');
		}, function() {
			if ($(this).hasClass('open')) return;
			$(this).removeClass('ui-state-focus');
		});

		$('#acc-core').trigger('click');

		moya.settings.mailinglistInstructions();
		moya.settings.setDiff();
		moya.settings.changes();
	},
	setDiff: function() {
		for (i in moya.settings.diffData) {
			var $dd = $('#' + i + '-label');
			$dd.append(
				'<div class="diff">'
				+ '<a href="' + moya.settings.urls.restore + '?item=' + i + '&height=100&width=300" class="thickbox" title="' + moya.settings.lang.settingsDiffRestore + '" class="restore">'
				+ moya.settings.lang.settingsDiffRestore
			+ '</div>'
		);
		}
		tb_init('a.thickbox');
	},
	restore: function(i) {
		var $el = $('#' + i);
		if ($el.attr('type') == 'checkbox') {
			var bool = moya.settings.diffData[i];
			if (bool == 1) {
				$el.attr('checked', 'checked');
			}
			else {
				$el.attr('checked', '');
			}
		}
		else {
			$el.val(moya.settings.diffData[i]);
		}
		$('#' + i + '-label div.diff').remove();
	},
	hideShowElement: function(id, value) {
		var $e = $("#" + id);
		var $dd = $e.parent();
		var $label = $('#' + id + '-label');
		if ($dd.get(0).tagName != 'DD') {
			$dd = $dd.parent();
		}
		if (value) {
			$dd.show();
			$dd.css('margin-left', '30px');
			$label.show();
			$label.css('margin-left', '30px');
		}
		else {
			$dd.hide();
			$label.hide();
		}
	},
	mailinglistInstructions: function() {
		var $el = $('#mailinglist-appendinstructions');
		$el.change(function(){
			moya.settings.hideShowElement('mailinglist-msginstructions', this.checked);
		});
		var h = $el.attr('checked');
		moya.settings.hideShowElement('mailinglist-msginstructions', h);
	},
	exitPage: function() {
		return moya.settings.settingsFormExitPageNotSaved;
	},
	changes: function() {
		$('#settings_form :input').change(function() {
			window.onbeforeunload = moya.settings.exitPage;
		});
		$('#settings_form .submit').click(function(){
			window.onbeforeunload = null;
		})
	}
};